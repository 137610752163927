import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guides.scss';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { GFLWeaponDB } from '../../../modules/exilium/components/exilium-weapon-db';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const ExiliumGuidesAtta: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page exilium-page gfl-guide'}
      game="exilium"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/gfl-exilium/">Girls' Frontline 2: Exilium</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/gfl-exilium/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Weapons & Attachments</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/exilium/categories/category_attachment.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Weapons & Attachments</h1>
          <h2>
            The Weapons & Attachment systems in Girls' Frontline 2: Exilium
            explained.
          </h2>
          <p>
            Last updated: <strong>01/12/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Weapons Types & Rarities" />
        <StaticImage
          src="../../../images/exilium/generic/weapons.webp"
          alt="Guides"
        />
        <p>
          The Weapon System in GFL is a way to improve your T-Doll performance
          in the battle. Each T-Doll can use one of{' '}
          <strong>7 weapon types</strong> (there's also an ammo type attached to
          each weapon type):
        </p>
        <ul>
          <li>
            <strong>Assault Rifle</strong> (Medium Ammo)
          </li>
          <li>
            <strong>Handgun</strong> (Light Ammo)
          </li>
          <li>
            <strong>Machine Gun</strong> (Heavy Ammo)
          </li>
          <li>
            <strong>Submachine Gun</strong> (Light Ammo)
          </li>
          <li>
            <strong>Shotgun</strong> (Shotgun Ammo)
          </li>
          <li>
            <strong>Rifle</strong> (Heavy Ammo)
          </li>
          <li>
            <strong>Blade</strong> (Melee)
          </li>
        </ul>
        <p>
          Weapons also come in 3 rarities - <strong>R, SR and SSR</strong> -
          that affect their stats, traits, effects. The higher rarity Weapon,
          the better it will perform.
        </p>
        <SectionHeader title="Weapon Stats" />
        <StaticImage
          src="../../../images/exilium/generic/weapon_view2.webp"
          alt="Guides"
        />
        <p>
          Weapons come with up to <strong>2 main stats</strong> - lower rarity
          (R and SR) weapons only have flat ATK stat while the SSR rarity
          weapons also posses a secondary main stat that is percentage instead
          (for example ATK%, Crit DMG%, Crit Rate% etc.).
        </p>
        <p>
          By leveling the weapon, the{' '}
          <strong>main stats value will increase</strong> - and as it's usually
          the case in gacha games - higher rarity weapons will have higher stat
          ranges.
        </p>
        <SectionHeader title="Weapon Trait and Effects" />
        <p>Weapons also have up to 3 passive effects:</p>
        <ul>
          <li>
            <strong>Effect</strong> is a passive effect available on all rarity
            weapons and it varies from simply improving damage to applying
            various effects,
          </li>
          <li>
            <strong>Trait</strong> is a passive affect available on SR and SSR
            rarity weapons - it's a bit simpler than the previous passive,
          </li>
          <li>
            <strong>Signature Effect</strong> is yet another passive effect
            available only on SSR weapons and to activate it, you need to equip
            the weapon on its 'owner'. Unless you do it, the trait won't
            activate.
          </li>
        </ul>
        <p>Here's how an example weapon looks like:</p>
        <Row xs={1} xxl={2} className="relic-set-container">
          <Col>
            <GFLWeaponDB name="Golden Melody" mode="box" />
          </Col>
        </Row>
        <SectionHeader title="Calibration (Dupes)" />
        <p>
          If you acquire multiples copies of the same weapon, you can use the{' '}
          <strong>Calibration</strong> feature to 'feed' them into themselves
          and this will increase the Effect (Trait and Signature Effect won't
          change).
        </p>
        <p>
          While for character dupes, we use the V0-V6 terminology to quickly
          refer to the number of dupes, for Weapons <strong>R1-R5</strong> is
          used instead.
        </p>
        <SectionHeader title="Weapon Attachments" />
        <StaticImage
          src="../../../images/exilium/generic/weapon_view.webp"
          alt="Guides"
        />
        <p>
          Weapons can also be further enhanced by equipping{' '}
          <strong>Attachments</strong> that provide them (and the character that
          uses the weapon of course) with a plethora of additional stats and
          even new passive effects. Here's an overview of the system:
        </p>
        <ul>
          <li>
            Weapon Attachments are basically the gear system in GFL 2 that will
            make you curse your luck (or rather the lack of it),
          </li>
          <li>
            There are 9 types of Weapon Attachments and each Weapon type has
            restriction when it comes to what type can be applied on them (
            <strong> 4 per Weapon</strong> ). For example, Muzzle can be
            equipped on any weapon type, but the Swords,
          </li>
          <li>
            Weapon Attachments have 4 rarities:{' '}
            <strong>Green (C), Blue (R), Purple (SR) and Gold (SSR)</strong>,
          </li>
          <ul>
            <li>
              Please note the rarities have different names in game, but it's
              easier to refer to them by the classical rarity system from other
              games so from C to SSR or by color,
            </li>
          </ul>
          <li>
            Weapon Attachments come with{' '}
            <strong>random stats and set effects</strong>:
          </li>
          <ul>
            <li>
              Muzzles comes with up to <strong>4 random stats</strong> based on
              the rarity,
            </li>
            <li>
              The rest can have up to{' '}
              <strong>3 random stats + a set effect</strong> (also based on the
              rarity).
            </li>
          </ul>
          <li>
            Higher rarity Attachments can be upgraded (using other Attachments
            and Sardis Gold) and this will increase the stats on it, but there's
            a limit put on the sub-stats - they can only be upgraded up to 200%
            of the base roll,
          </li>
          <li>
            You can also reroll the attachments, but this will only affect the
            upgrades - the initial stats the Attachment started with and the
            initial rolls can't be modified,
          </li>
          <li>
            You need to{' '}
            <strong>
              {' '}
              match the set effect on the 3 remaining Attachments to activate it
            </strong>
            . Thankfully, you can select the set effect you want to farm.
          </li>
        </ul>
        <p>Here's how an example Attachment upgrade looks like:</p>
        <StaticImage
          src="../../../images/exilium/generic/weapon_stats.webp"
          alt="Guides"
        />
        <h5>Possible Attachment Stats</h5>
        <p>Here's a list of all stats you can roll on your Attachment:</p>
        <ul className="with-image">
          <li>
            <div className="category">
              <StaticImage
                src="../../../images/exilium/icons/stat_atk.png"
                alt="Stat"
              />
              <strong>Attack (ATK)</strong>
            </div>{' '}
          </li>
          <li>
            <div className="category">
              <StaticImage
                src="../../../images/exilium/icons/stat_atk.png"
                alt="Stat"
              />
              <strong>Attack% (ATK%)</strong>
            </div>{' '}
          </li>
          <li>
            <div className="category">
              <StaticImage
                src="../../../images/exilium/icons/stat_def.png"
                alt="Stat"
              />
              <strong>Defense (DEF)</strong>
            </div>{' '}
          </li>
          <li>
            <div className="category">
              <StaticImage
                src="../../../images/exilium/icons/stat_def.png"
                alt="Stat"
              />
              <strong>Defense% (DEF%)</strong>
            </div>{' '}
          </li>
          <li>
            <div className="category">
              <StaticImage
                src="../../../images/exilium/icons/stat_hp.png"
                alt="Stat"
              />
              <strong>Health (HP)</strong>
            </div>{' '}
          </li>
          <li>
            <div className="category">
              <StaticImage
                src="../../../images/exilium/icons/stat_hp.png"
                alt="Stat"
              />
              <strong>Health% (HP%)</strong>
            </div>{' '}
          </li>
          <li>
            <div className="category">
              <StaticImage
                src="../../../images/exilium/icons/stat_critdmg.png"
                alt="Stat"
              />
              <strong>Critical Damage%</strong>
            </div>{' '}
            - Can only appear on the Muzzle attachment.
          </li>
          <li>
            <div className="category">
              <StaticImage
                src="../../../images/exilium/icons/stat_crit.png"
                alt="Stat"
              />
              <strong>Critical Hit% </strong>
            </div>{' '}
          </li>
        </ul>
        <h5>Where to get Attachments?</h5>
        <p>
          You can obtain attachments from <strong>Personal Study</strong> that
          unlocks once you reach Commander Level <strong>23</strong>.
        </p>
        <p>
          To learn more about Attachments and the best ones to farm, check our
          other guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Attachments Farming"
            link="/gfl-exilium/guides/attachment-farming"
            image={
              <StaticImage
                src="../../../images/exilium/categories/category_atta.webp"
                alt="Attachments Farming"
              />
            }
          />
        </Row>
        <SectionHeader title="Video" />
        <p>
          If you want to learn more or just see the various systems in action,
          check this video:
        </p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="EssZs-aX3-k" />
          </Col>
        </Row>
        <SectionHeader title="Credits" />
        <p>Some of the images used in this guide come from this video:</p>
        <Row className="video">
          <Col xs={12} lg={4}>
            <YoutubeEmbed embedId="IjwBaUxywnc" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default ExiliumGuidesAtta;

export const Head: React.FC = () => (
  <Seo
    title="Weapons & Attachments | Girls' Frontline 2: Exilium | Prydwen Institute"
    description="The Weapons & Attachment systems in Girls' Frontline 2: Exilium explained."
    game="exilium"
  />
);
